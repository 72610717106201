
import { defineComponent } from "vue";
import axios from "@/plugins/axios";
import HoisterParams from "@/components/Form.HoisterParams/index.vue";

const getData = () => ({
  params: {
    type: "垂直输送机设计图基本参数表",
    name: "",
    message: "",
    company: "",
    // eslint-disable-next-line @typescript-eslint/camelcase
    project_name: "",
    // eslint-disable-next-line @typescript-eslint/camelcase
    phone_number: ""
  },
  isLoading: false
});

export default defineComponent({
  name: "HoisterPrice",

  data: getData,

  components: {
    HoisterParams
  },

  methods: {
    handleSubmit () {
      const params = {
        ...this.params,
        data: (this.$refs as any).hoister.$data
      };

      const handleThen = () => {
        window.scrollTo(0, 0);
        (this.$refs as any).hoister.reset();
        Object.assign(this.$data, getData());
        this.$router.push("/prices/hoister/created");
      };

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const handleCatch = () => {};

      const handleFinally = () => {
        this.isLoading = false;
      };

      this.isLoading = true;

      axios.post("sale/tracks/create", params)
        .then(handleThen)
        .catch(handleCatch)
        .finally(handleFinally);
    }
  }
});
