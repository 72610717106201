
import { defineComponent } from "vue";
import TransplotRouteType from "./TransplotRouteType.vue";

const routeGroups = [
  ["Z型", "C型", "CC型", "CZ型", "ZC型", "F型", "E型"],
  ["ZE型", "FE型", "EE型", "L型", "LF型", "LL型"]
];

export default defineComponent({
  name: "TransplotRoutes",
  components: {
    TransplotRouteType
  },
  props: {
    value: String
  },
  computed: {
    routeGroups: () => routeGroups
  }
});
